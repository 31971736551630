<template>
  <div class="ml-2">
    <div class="text-left problem-card-title">
      <h3 class="display-3">
        <span
          style="font-size: 24px;"
          :class="isTimeUp ? 'text-danger' : ''"
        >
          <span v-if="isTask">
            {{ formatTime(increasingMinutes) }}:{{ formatTime(increasingSeconds) }} {{ label }}
          </span>
          <span v-else>
            {{ formatTime(decreasingMinutes) }}:{{ formatTime(decreasingSeconds) }} {{ label }}
          </span>
        </span>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    const timer = this.time.split(':')
    return {
      increasingMinutes: 0,
      increasingSeconds: 0,
      decreasingMinutes: +timer[0],
      decreasingSeconds: +timer[1],
      timer: null, // Timer reference
      isTimeUp: false,
      maxMinute: +timer[0],
      maxSecond: +timer[1],
    }
  },
  computed: {
    label() {
      if (this.isTimeUp) {
        return '(Time Exceeded)'
      }
      return this.type === 'prepare' ? '(Prepare)' : '(Task Time)'
    },
    isTask() {
      return this.type === 'task'
    },
  },
  mounted() {
    if (this.type === 'prepare') {
      this.$root.$off('endPreparationTime')
      this.$root.$on('endPreparationTime', () => {
        this.$emit('onEnd', `${this.increasingMinutes}:${this.increasingSeconds}`)
        this.$root.$off('endPreparationTime')
      })
    }
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        // eslint-disable-next-line no-unused-expressions
        this.isTask ? this.updateTimeForTask() : this.updateTime()
      }, 1000)
    },
    increaseTime() {
      if (this.increasingSeconds === 59) {
        // eslint-disable-next-line no-plusplus
        this.increasingMinutes++
        this.increasingSeconds = 0
      } else {
        // eslint-disable-next-line no-plusplus
        this.increasingSeconds++
      }
    },
    decreaseTime() {
      if (this.decreasingSeconds === 0) {
        // eslint-disable-next-line no-plusplus
        this.decreasingMinutes--
        this.decreasingSeconds = 59
      } else {
        // eslint-disable-next-line no-plusplus
        this.decreasingSeconds--
      }
    },
    updateTimeForTask() {
      if (this.increasingMinutes >= this.maxMinute && this.increasingSeconds >= this.maxSecond && !this.isTimeUp) {
        this.isTimeUp = true
      }
      this.increaseTime()
    },
    updateTime() {
      if (this.decreasingMinutes === 0 && this.decreasingSeconds === 0) {
        // Do something when the countdown reaches 0
        clearInterval(this.timer)
        this.isTimeUp = true
        this.$emit('onEnd', `${this.increasingMinutes}:${this.increasingSeconds}`)
      } else {
        this.decreaseTime()
      }
      this.increaseTime()
    },
    formatTime(value) {
      return value < 10 ? `0${value}` : value
    },
  },
}
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}
</style>
