var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isStatement && _vm.hasStatement &&
         (_vm.engine == 'adaptivepathengine' || _vm.engine == 'linearstandaloneengine'))?_c('div',{staticClass:"back-to-statement"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-icon",staticStyle:{"color":"white"},attrs:{"title":_vm.$t('navigations.back-to-statement'),"variant":"flat-info"},on:{"click":function($event){_vm.isStatement = true}}},[_c('feather-icon',{attrs:{"icon":"RewindIcon","size":"35"}})],1)],1):_vm._e(),(_vm.canHaveTimer && _vm.problem.rules && _vm.problem.rules.task_time && _vm.problem.rules.preparation_time)?_c('div',{staticClass:"timer-for-problem"},[(_vm.hasTaskTimerStarted)?_c('Timer',{key:_vm.problem.rules.task_time,ref:"taskTimer",attrs:{"type":"task","time":_vm.problem.rules.task_time}}):_c('Timer',{key:_vm.problem.rules.preparation_time,attrs:{"time":_vm.problem.rules.preparation_time,"type":"prepare"},on:{"onEnd":(time) => { _vm.hasTaskTimerStarted = true; _vm.preparationTime = time }}})],1):_vm._e(),(_vm.canViewLangLevelTab)?_c('div',{staticClass:"lang-level-select mr-2 mb-2"},_vm._l((['A1', 'A2', 'B1', 'B2']),function(label,index){return _c('b-button',{key:index,attrs:{"variant":_vm.level === index + 1 ? 'success' : 'flat-success'},on:{"click":function($event){return _vm.$emit('onRefetch', index + 1)}}},[_vm._v(" "+_vm._s(_vm.getLangLevelTransText(label))+" ")])}),1):_vm._e(),(
      _vm.hasStatement && _vm.isStatement &&
        (_vm.engine == 'adaptivepathengine' || _vm.engine == 'linearstandaloneengine')
    )?[(_vm.isVideo)?_c('video-statement',{attrs:{"level":_vm.level,"video":_vm.problem.video,"is-last-statement":true,"engine":_vm.engine},on:{"continueToProblem":function($event){_vm.skipProblemPart ? _vm.nextProblem() : _vm.moveToQuestion()}}}):(_vm.problem.hotspotPoints)?_c('div',[_c('animated-layout',{attrs:{"enabled":_vm.problem.animated_layout,"is-edit-mode":false,"teleport-target":"problemFeedBack","template-id":_vm.problem.animated_template_id}},[_c('hot-spot-statement',{attrs:{"hotspot":_vm.problem,"level":_vm.level,"engine":_vm.engine,"school-settings":_vm.schoolSettings,"district-settings":_vm.districtSettings},on:{"submitQuestion":_vm.submitQuestion,"proceedToQuestion":function($event){return _vm.moveToQuestion()},"nextProblem":_vm.nextProblem}})],1)],1):_c('AnimatedLayout',{attrs:{"enabled":_vm.problem.st_template && !!_vm.problem.st_template.animated_layout,"template-id":_vm.problem.st_template && _vm.problem.st_template.animated_template_id,"is-edit-mode":false,"teleport-target":"statementActionForAnimationLayout"}},[_c('statement',{attrs:{"statement":{
          text: _vm.problem.statement,
          image: _vm.problem.statement_image,
          audio: _vm.problem.statement_audio,
          template: _vm.problem.st_template,
          game:
            _vm.problem.game && _vm.problem.game.length > 0 ? _vm.problem.game[0] : null,
        },"level":_vm.level,"is-last-statement":true,"engine":_vm.engine,"loader-id":_vm.loaderId,"school-settings":_vm.schoolSettings,"district-settings":_vm.districtSettings},on:{"continueToProblem":function($event){return _vm.moveToQuestion()}}})],1)]:[_c('animated-layout',{attrs:{"enabled":_vm.problem.animated_layout,"is-edit-mode":false,"is-problem-body-preview":true,"teleport-target":"problemFeedBack","template-id":_vm.problem.animated_template_id}},[_c(_vm.getProblemComponent,{tag:"component",attrs:{"questions":_vm.problem.questions,"feedback":_vm.feedback ? _vm.feedback : {},"feedback-question":_vm.feedBackQuestion,"mode":_vm.feedback ? 'feedback' : 'question',"level":_vm.level,"is-processing":false,"rules":_vm.rules,"engine":_vm.engine,"has-minimal-view":_vm.hasMinimalView,"school-settings":_vm.schoolSettings,"district-settings":_vm.districtSettings},on:{"submitQuestion":_vm.submitQuestion,"nextQuestion":_vm.nextProblem}})],1)],_c('audio',{ref:"successAudio",attrs:{"src":"https://assets.mixkit.co/sfx/preview/mixkit-fantasy-game-success-notification-270.mp3"}}),(_vm.obtainedBadges)?_c('BadgesCelebration',{key:_vm.problem?.id,attrs:{"badge-info":_vm.obtainedBadges}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }