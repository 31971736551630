<template>
  <b-row>
    <b-col
      v-if="!data.result && !teachersStudentId"
      class="pt-3"
    >
      <FailedSympathy
        v-if="resultInfo.is_answer_prepared && resultInfo.status === 'failed'"
        :processing="processing"
        :self="self"
        :lesson="resultInfo.lesson"
        :result-info="resultInfo"
        @viewResult="getResult"
      />
      <congratulation
        v-else-if="resultInfo.is_answer_prepared"
        :processing="processing"
        :self="self"
        :lesson="resultInfo.lesson"
        @viewResult="getResult"
      />
      <wait-for-answer v-else
                       :processing="processing"
                       :self="self"
                       :lesson="resultInfo.lesson"
                       :result-info="resultInfo"
                       @viewResult="getResult"
      />
    </b-col>
    <b-col
      v-else-if="data.result"
      md="12"
    >
      <b-card
        v-if="data"
        text-variant="center"
        class="card not-student-card"
      >
        <b-col
          md="12"
          class="p-0 d-flex justify-content-start flex-wrap pb-1"
        >
          <div>
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="GO_BACK()"
            >
              <feather-icon
                size="20"
                icon="ArrowLeftIcon"
              />
            </b-button>
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="$router.push({name: `${self.usertype}-view-students`,params: { id: classId },})"
            >
              <span>
                {{ $t('labels.classroom') }}
              </span>
            </b-button>
            |
            <b-button
              variant="flat-secondary"
              class="btn-icon"
              @click="() => {
                if (classTestId) {
                  return $router.push({name:`${self.usertype}-class-test`,params:{classId} })
                }
                $router.push({
                  name: `${self.usertype}-lessons`,
                  params: { id: data.course },
                  query: { group: data.gid },
                })
              }
              "
            >
              {{ $t("actions.list-of-lessons") }}
            </b-button>
          </div>

        </b-col>
        <!-- message -->
        <b-col>
          <b-row>
            <b-col
              v-if="data.image"
              :md="data.image ? '2' : '0'"
              :sm="data.image ? '2' : '0'"
              offset="3"
            >
              <b-img
                :src="data.image"
                style="max-height: 200px"
                thumbnail
                fluid
              />
            </b-col>
            <b-col
              :md="data.image ? '6' : '12'"
              sm="12"
              class="mt-2 sm-mt-0 d-flex flex-column justify-content-end pl-0"
            >
              <h1 class="mb-1 mt-50">
                {{ $t("student-test-module.lesson-completed") }}
              </h1>
              <b-card-text>
                <h5>
                  <span v-if="teachersStudentId">
                    <strong> {{ data.userName }}</strong>
                    {{
                      $t("student-test-module.completed-all-standards-student")
                    }}
                  </span>
                  <span
                    v-else
                  >{{ $t("student-test-module.completed-all-standards") }}
                  </span>
                  <strong> {{ data.lesson }}</strong>
                </h5>
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
        <!-- message -->

        <!-- stats -->
        <div
          v-if="!isResultDDF"
          class="d-flex justify-content-around flex-wrap mt-4"
        >
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.required-percentage") }}
            </b-card-text>
            <span
              class="font-large-1 font-weight-bold"
            >{{ data.required_percentage || 40 }}%</span>
          </div>
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.obtained-percentage") }}
            </b-card-text>
            <span
              class="font-large-1 font-weight-bold"
              :class="{
                'text-danger': hasFailed,
                'text-success': !hasFailed,
              }"
            >{{ data.score_percentage }}%</span>
          </div>
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.obtain-point") }}
            </b-card-text>
            <span class="font-large-1 font-weight-bold">{{
              data.obtained_points
            }}</span>
          </div>
          <div class="text-center">
            <b-card-text>
              {{ $t("student-test-module.taken-time") }}
            </b-card-text>
            <span
              v-if="data.time_spent.seconds !== 'null'"
              class="font-large-1 font-weight-bold"
            >
              {{ data.time_spent.seconds }}
            </span>
            <span
              v-else
              class="font-large-1 font-weight-bold"
            > N/A </span>
          </div>
        </div>
        <!-- stats -->
        <div class="mt-2">
          <b-table
            responsive="sm"
            class="table-hover"
            :items="getResultRows"
            :fields="fields"
            borderless
          >
            <template #cell(status)="d">
              <span>
                <feather-icon
                  v-if="d.item.status === 'passed' || isResultDDF"
                  size="20"
                  icon="CheckIcon"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  size="20"
                  icon="XIcon"
                  class="text-danger"
                />
              </span>
            </template>
            <template #cell(passed_problems)="d">
              <span
                v-if="isResultDDF"
                class="text-success"
              >
                {{ d.item.passed_problems }}
              </span>
              <span
                v-else
                :class="{
                  'text-danger': d.item.status != 'passed',
                  'text-success': d.item.status == 'passed',
                }"
              >
                {{ d.item.passed_problems }}
              </span>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <!-- <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="
                $router.replace({
                  name: `${self.usertype}-lessons`,
                  params: { id: data.course },
                  query: { group: data.gid },
                })
              "
            >
              {{ $t("actions.to-class-list") }}
            </b-button> -->
            <!-- <b-button
              v-if="hasFailed"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="$emit('onRetakeExam')"
            >
              {{ $t('student-test-module.try-again') }}
            </b-button> -->
            <b-button
              v-if="data.next_lesson != null && data.next_lesson !== 0"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="() => {
                if (classTestId) {
                  return $router.push({name:`${self.usertype}-class-test`,params:{classId} })
                }
                $router.push({
                  name: `${self.usertype}-lessons`,
                  params: { classId, id: resultInfo.course },
                  query: { group: data.gid },
                })
              }
              "
            >
              {{ $t("student-test-module.next-lesson") }}
            </b-button>
            <b-button
              v-else-if="data.is_course_completed"
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="
                () => {
                  if (classTestId) {
                    return $router.push({name:`${self.usertype}-class-test`,params:{classId} })
                  }
                  $router.push({
                    name: `${self.usertype}-course-result`,
                    params: { id: data.course },
                  })
                }
              "
            >
              {{ $t("student-test-module.course-result") }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-skeleton
        animation="wave"
        width="85%"
      />
      <b-skeleton
        animation="wave"
        width="55%"
      />
      <b-skeleton
        animation="wave"
        width="70%"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BTable,
  BImg,
  BSkeleton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import useApollo from '@/@core/graphql/useApollo'
import { STATUS_IDLE } from '@/const/avatar'
import i18n from '@/libs/i18n'
import Congratulation from './Congralution.vue'
import WaitForAnswer from './WaitForAnswer.vue'
import FailedSympathy from './FailedSympathy.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTable,
    BButton,
    BImg,
    Congratulation,
    BSkeleton,
    WaitForAnswer,
    FailedSympathy,
  },
  props: {
    teachersStudentId: {
      type: [Object, Number],
      default: () => null,
    },
    resultInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      self: getUserData(),
      data: {},
      classId: Number(this.$route.params.classId),
      lessonId: Number(this.$route.params.id),
      eventId: Number(this.$route.query.event_id),
      processing: false,
      isResultDDF: false,
      fields: [
        { key: 'code', label: i18n.tc('points-setting-module.code') },
        { key: 'standard', label: i18n.tc('standard') },
        { key: 'passed_problems', label: i18n.tc('passed_problems') },
        { key: 'status', label: i18n.tc('issue-report-module.status') },
      ],
    }
  },
  computed: {
    hasFailed() {
      return !this.data.hasPassed
    },
    getResultRows() {
      return this.data.result.map(e => ({
        code: e.code,
        standard: e.standard,
        passed_problems: `${e.passed_problems}`,
        status:
          this.data.score_percentage >= this.data.required_percentage
            ? 'passed'
            : 'failed',
      }))
    },
    classTestId() {
      return this.$route.query.testId
    },
  },
  watch: {
    hasFailed: {
      handler(bool) {
        if (bool && this.data.result) {
          this.getResult()
        }
      },
      immediate: true,
    },
  },
  created() {
    localStorage.removeItem('previousTimestamp')
    if (this.teachersStudentId) this.getResult() // directly show result when student id is passed
  },
  methods: {
    getResult() {
      this.processing = true
      useApollo
        .getLessonPassedResult({
          user_id: this.teachersStudentId
            ? this.teachersStudentId
            : this.self.id,
          group_id: this.classId,
          lesson_id: this.lessonId,
          event_id: this.eventId,
          // eslint-disable-next-line no-unused-vars
        })
        .then(response => {
          this.$store.commit('appConfig/UPDATE_CURRENT_STATUS', STATUS_IDLE)
          const resultData = response.data.loaders.data[0]
          this.isResultDDF = resultData.engine === 'doublefallbackengine'
          const passedResult = resultData.passedProblems.map(data => {
            const passedProblem = resultData.passedProblems.map(
              arr => arr.standard?.code === data.standard?.code,
            ).length
            return {
              code: data.standard?.code,
              standard: data.standard?.name,
              passed_problems: `${passedProblem} of ${resultData.totalProblems}`,
              status: resultData.status,
            }
          })
          const codes = []
          const passedResultUnique = []
          passedResult.forEach(value => {
            if (!codes.includes(value.standard?.code)) {
              codes.push(value.standard?.code)
              passedResultUnique.push(value)
            }
          })

          let timeSpent = resultData.time_spent
          timeSpent = {
            seconds: `${timeSpent}`,
          }

          this.data = {
            result: passedResultUnique,
            required_percentage: resultData.lesson.pass_percent,
            score_percentage: resultData.obtainedPercent?.toFixed(),
            image: resultData.lesson.image,
            lesson: resultData.lesson.name,
            obtained_points: resultData.points,
            time_spent: timeSpent,
            course: resultData.lesson.course_id,
            is_course_completed: this.resultInfo.is_course_completed,
            next_lesson: this.resultInfo.next_lesson,
            hasPassed: this.resultInfo.status === 'passed' || (resultData.obtainedPercent >= resultData.lesson.pass_percent),
            userName: resultData.user?.fullname,
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
  },
}
</script>

<style>
  .dark-layout .table-hover tbody tr:hover {
    background-color: #384361;
  }
</style>
