<template>
  <div class="badge-celebration">
    <b-toast
      v-if="show && !!badgeInfo.text"
      :visible="true"
      auto-hide-delay="30000"
      variant="success"
      header-class="d-none"
      body-class="bg-white"
      toast-class="toast-postition"
    >
      <div v-if="badgeInfo"
           id="badge-message"
      >
        <span v-if="badgeInfo.type === 'streak_badge'"
              class="text-left"
              v-html="badgeInfo.text"
        />
        <span v-else-if="badgeInfo.type === 'perseverance_badge'"
              class="text-left"
              v-html="badgeInfo.text"
        />

      </div>
    </b-toast>
  </div>
</template>
<script setup>
import { BToast } from 'bootstrap-vue'
import { ref } from 'vue'

const props = defineProps({
  badgeInfo: {
    type: Object,
    default: () => null,
  },
})

const show = ref(false)
const badgeInfo = ref(null)
const obtainedBadge = Object.entries(props.badgeInfo).find(([, value]) => value === true)
if (obtainedBadge) {
  show.value = true
  badgeInfo.value = {
    type: obtainedBadge[0],
    text: props.badgeInfo.text,
    audio: props.badgeInfo.audio,
  }
  if (props.badgeInfo.audio) {
    const audio = new Audio(props.badgeInfo.audio)
    audio.play()
  }
}

</script>
<style lang="scss">
.badge-celebration {
  @keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes float {
0% {box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);transform: translatey(0px);}
50% {
  box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
  transform: translatey(-20px);
}
100% {
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
  transform: translatey(0px);
}
}

#badge-message {
    border-radius: 5px;
    font-family: "PatuaOne",cursive;font-size:1.5em;text-align:center;animation:shake5s;padding:8px;cursor:pointer;animation-iteration-count:infinite;
}

.badge p{
  font-size: 14px;
  color: black;
}

.toast-postition {
  margin-top:200px !important;
}

}
</style>
